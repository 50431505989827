import { Routes } from "@angular/router";
import { AuthGuard } from "./core/services/auth-guard.service";
import { DietitianAuthGuard } from "./core/services/dietitian-auth-guard.service";
import { PhysioAuthGuard } from "./core/services/physio-auth-guard.service";
import { TrainerAuthGuard } from "./core/services/trainer-auth-guard.service";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";

export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/gym/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Session" },
      },
      {
        path: "physiotherapist",
        loadChildren: () =>
          import("./modules/physiotherapist/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Physiotherapist" },
      },
      {
        path: "dietitian",
        loadChildren: () =>
          import("./modules/dietitian/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Dietitian" },
      },
      {
        path: "trainer",
        loadChildren: () =>
          import("./modules/trainer/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Trainer" },
      },
     
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/gym/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard", breadcrumb: "DASHBOARD" },
      },
      {
        path: "consultation/dietitian-list",
        loadChildren: () =>
          import("./modules/gym/app-dietitian/app-dietitian.module").then(
            (m) => m.AppDietitianModule
          ),
        data: { title: "Dietitian", breadcrumb: "Dietitian" },
      },
      {
        path: "message/offers",
        loadChildren: () =>
          import("./modules/gym/offers/offers.module").then(
            (m) => m.OffersModule
          ),
        data: { title: "Offers", breadcrumb: "Offers" },
      },
      {
        path: "message/quotes",
        loadChildren: () =>
          import("./modules/gym/quotes/quotes.module").then(
            (m) => m.QuotesModule
          ),
        data: { title: "Quotes", breadcrumb: "Quotes" },
      },
      {
        path: "message/steam-attendance",
        loadChildren: () =>
          import("./modules/gym/steam-attendance/steam-attendance.module").then(
            (m) => m.SteamAttendanceModule
          ),
        data: { title: "Steam Attendance", breadcrumb: "Steam Attendance" },
      },
      {
        path: "locker",
        loadChildren: () =>
          import("./modules/gym/app-locker/app-locker.module").then(
            (m) => m.AppLockerModule
          ),
        data: { title: "Locker", breadcrumb: "Locker" },
      },

      {
        path: "members",
        loadChildren: () =>
          import("./modules/gym/member/member.module").then(
            (m) => m.MemberModule
          ),
        data: { title: "Member", breadcrumb: "Member" },
      },
      {
        path: "employees",
        loadChildren: () =>
          import("./modules/gym/trainer/trainer.module").then(
            (m) => m.TrainerModule
          ),
        data: { title: "Employee", breadcrumb: "Employee" },
      },
      {
        path: "weight-measurement",
        loadChildren: () =>
          import(
            "./modules/gym/weight-measurement/weight-measurement.module"
          ).then((m) => m.AppWeightMeasurementModule),
        data: { title: "Weight Measurement", breadcrumb: "Weight Measurement" },
      },
      {
        path: "body-measurement",
        loadChildren: () =>
          import("./modules/gym/body-measurement/body-measurement.module").then(
            (m) => m.AppBodyMeasurementModule
          ),
        data: { title: "Body Measurement", breadcrumb: "Body Measurement" },
      },
      {
        path: "workout",
        loadChildren: () =>
          import("./modules/gym/workout/workout.module").then(
            (m) => m.AppWorkoutModule
          ),
        data: { title: "Workout", breadcrumb: "Workout" },
      },
      {
        path: "meal-food",
        loadChildren: () =>
          import("./modules/gym/meal-food/meal-food.module").then(
            (m) => m.AppMealfoodModule
          ),
        data: { title: "Meal Food", breadcrumb: "Meal Food" },
      },
      {
        path: "mealplan-name",
        loadChildren: () =>
          import("./modules/gym/mealplan-name/mealplan-name.module").then(
            (m) => m.AppMealplannameModule
          ),
        data: { title: "Meal Plan Name", breadcrumb: "Meal Plan Name" },
      },

      {
        path: "threshold-settings",
        loadChildren: () =>
          import(
            "./modules/gym/threshold-settings/threshold-settings.module"
          ).then((m) => m.AppThresholdsettingsModule),
        data: { title: "Threshold Setting", breadcrumb: "Threshold Setting" },
      },
      {
        path: "workoutplan-name",
        loadChildren: () =>
          import("./modules/gym/workoutplan-name/workoutplan-name.module").then(
            (m) => m.AppWorkoutplannameModule
          ),
        data: { title: "Workout Plan Name", breadcrumb: "Workout Plan Name" },
      },
      {
        path: "equipment",
        loadChildren: () =>
          import("./modules/gym/equipment/equipment.module").then(
            (m) => m.AppEquipmentModule
          ),
        data: { title: "Equipment", breadcrumb: "Equipment" },
      },
      {
        path: "stretch",
        loadChildren: () =>
          import("./modules/gym/stretch/stretch.module").then(
            (m) => m.AppStretchModule
          ),
        data: { title: "Stretch", breadcrumb: "Stretch" },
      },

      {
        path: "warmup",
        loadChildren: () =>
          import("./modules/gym/warmup/warmup.module").then(
            (m) => m.AppWarmupModule
          ),
        data: { title: "Warm Up", breadcrumb: "Warm Up" },
      },
      {
        path: "exercise",
        loadChildren: () =>
          import("./modules/gym/exercise/exercise.module").then(
            (m) => m.AppExerciseModule
          ),
        data: { title: "Exercise", breadcrumb: "Exercise" },
      },
      {
        path: "trainer-type",
        loadChildren: () =>
          import("./modules/gym/trainer-skills/trainer-skills.module").then(
            (m) => m.AppTrainerSkillsModule
          ),
        data: { title: "Trainer Type", breadcrumb: "Trainer Type" },
      },
      {
        path: "locker-update",
        loadChildren: () =>
          import("./modules/gym/locker-update/locker-update.module").then(
            (m) => m.AppLockerUpdateModule
          ),
        data: { title: "Locker", breadcrumb: "Locker" },
      },
      {
        path: "consultation-fees-update",
        loadChildren: () =>
          import(
            "./modules/gym/consultation-fees-update/consultation-fees-update.module"
          ).then((m) => m.AppConsultationFeesUpdateModule),
        data: {
          title: "Consultation Fees Update",
          breadcrumb: "Consultation Fees Update",
        },
      },
      {
        path: "hub_settings",
        loadChildren: () =>
          import("./modules/gym/hub-update/hub-update.module").then(
            (m) => m.AppHubUpdateModule
          ),
        data: {
          title: "Update Hub Settings",
          breadcrumb: "Update Hub Settings",
        },
      },
      {
        path: "consultation/physiotherapist-list",
        loadChildren: () =>
          import("./modules/gym/physiotherapist/physiotherapist.module").then(
            (m) => m.AppPhysiotherapistModule
          ),
        data: { title: "Physiotherapist", breadcrumb: "Physiotherapist" },
      },
      {
        path: "meal-plan",
        loadChildren: () =>
          import("./modules/gym/meal-plan/meal-plan.module").then(
            (m) => m.AppMealPlanModule
          ),
        data: { title: "Meal Plan", breadcrumb: "Meal Plan" },
      },

      {
        path: "membership",
        loadChildren: () =>
          import("./modules/gym/membership/membership.module").then(
            (m) => m.AppMembershipModule
          ),
        data: { title: "Membership", breadcrumb: "Membership" },
      },
      {
        path: "trainer-membership",
        loadChildren: () =>
          import("./modules/gym/trainer-membership/trainer-membership.module").then(
            (m) => m.AppTrainnerMembershipModule
          ),
        data: { title: "Trainer Membership", breadcrumb: "Trainer Membership" },
      },
      {
        path: "role",
        loadChildren: () =>
          import("./modules/gym/administration/role/role.module").then(
            (m) => m.AppRoleModule
          ),
        data: { title: "Role", breadcrumb: "Role" },
      },

      {
        path: "users",
        loadChildren: () =>
          import("./modules/gym/administration/users/users.module").then(
            (m) => m.AppusersModule
          ),
        data: { title: "Users", breadcrumb: "Users" },
      },

      {
        path: "role-permission",
        loadChildren: () =>
          import(
            "./modules/gym/administration/role-permission/role-permission.module"
          ).then((m) => m.AppRolepermissionModule),
        data: { title: "Role Permission", breadcrumb: "Role Permission" },
      },

      {
        path: "doctor",
        loadChildren: () =>
          import("./modules/gym/doctor/doctor.module").then(
            (m) => m.AppDoctorModule
          ),
        data: { title: "Doctor", breadcrumb: "Doctor" },
      },

      {
        path: "attendance-settings",
        loadChildren: () =>
          import(
            "./modules/gym/attendance-settings/attendance-settings.module"
          ).then((m) => m.AppAttendancesettingsModule),
        data: {
          title: "Attendance Settings",
          breadcrumb: "Attendance Settings",
        },
      },
      {
        path: "employee-attendance-setting",
        loadChildren: () =>
          import(
            "./modules/gym/attendance-setting/attendance-setting.module"
          ).then((m) => m.AppAttendancesettingModule),
        data: {
          title: "Employee Attendance Setting",
          breadcrumb: "Employee Attendance Setting",
        },
      },
      {
        path: "fees",
        loadChildren: () =>
          import("./modules/gym/fees/fees.module").then((m) => m.AppFeesModule),
        data: { title: "Fees", breadcrumb: "Fees" },
      },
      {
        path: "fees/:id",
        loadChildren: () =>
          import("./modules/gym/fees/fees.module").then((m) => m.AppFeesModule),
        data: { title: "Fees", breadcrumb: "Fees" },
      },

      {
        path: "notifications",
        loadChildren: () =>
          import("./modules/gym/notifications/notifications.module").then(
            (m) => m.GymNotificationsModule
          ),
        data: { title: "Notifications", breadcrumb: "Notifications" },
      },
      {
        path: "change-password",
        loadChildren: () =>
          import("./modules/gym/change-password/change-password.module").then(
            (m) => m.GymChangePasswordModule
          ),
        data: { title: "Change Password", breadcrumb: "Change Password" },
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/gym/reports/reports.module").then(
            (m) => m.AppReportsModule
          ),
        data: { title: "Reports", breadcrumb: "Reports" },
      },
      {
        path: "physiotherapists",
        loadChildren: () =>
          import(
            "./modules/physiotherapist/physiotherapist/physiotherapist.module"
          ).then((m) => m.AppPhysiotherapistModule),
        data: { title: "Physiotherapist", breadcrumb: "Physiotherapist" },
      },
      {
        path: "employee-type",
        loadChildren: () =>
          import("./modules/gym/employee-type/employee-type.module").then(
            (m) => m.AppEmployeeTypeModule
          ),
        data: { title: "Employee Type", breadcrumb: "Employee Type" },
      },
      {
        path: "session-settings",
        loadChildren: () =>
          import("./modules/gym/session-settings/session-settings.module").then(
            (m) => m.AppSessionsettingsModule
          ),
        data: { title: "Session Settings", breadcrumb: "Session Settings" },
      },
      {
        path: "feedback",
        loadChildren: () =>
          import("./modules/gym/complaints/complaints.module").then(
            (m) => m.AppComplaintsModule
          ),
        data: { title: "Feedback", breadcrumb: "Feedback" },
      },
      {
        path: "leave-request",
        loadChildren: () =>
          import("./modules/gym/leave-request/leave-request.module").then(
            (m) => m.AppLeaveRequestModule
          ),
        data: { title: "Leave Request", breadcrumb: "Leave Request" },
      },
      {
        path: "calory-settings",
        loadChildren: () =>
          import("./modules/gym/calory-settings/calory-settings.module").then(
            (m) => m.AppCalorySettingsModule
          ),
        data: { title: "Calory Settings", breadcrumb: "Calory Settings" },
      },
      {
        path: "heartrate",
        loadChildren: () =>
          import(
            "./modules/gym/heartrate-settings/heartrate-settings.module"
          ).then((m) => m.AppHeartrateSettingsModule),
        data: {
          title: "Heart rate Settings",
          breadcrumb: "Heart rate Settings",
        },
      },
      {
        path: "indoor-fitness",
        loadChildren: () =>
          import("./modules/gym/indoor-fitness/indoor-fitness.module").then(
            (m) => m.AppIndoorFitnessModule
          ),
        data: {
          title: "Indoor Fitness",
          breadcrumb: "Indoor Fitness",
        },
      },
      {
        path: "gym-timings",
        loadChildren: () =>
          import("./modules/gym/gym-timings/gym-timings.module").then(
            (m) => m.AppGymTimingsModule
          ),
        data: {
          title: "Gym Timings",
          breadcrumb: "Gym Timings",
        },
      },
      {
        path: "accounts/ledger",
        loadChildren: () =>
          import("./modules/gym/accounts/ledger/ledger.module").then(
            (m) => m.AppLedgerModule
          ),
        data: {
          title: "Ledger",
          breadcrumb: "Ledger",
        },
      },
      {
        path: "accounts/expenses",
        loadChildren: () =>
          import("./modules/gym/accounts/expenses/expenses.module").then(
            (m) => m.AppExpensesModule
          ),
        data: {
          title: "Expenses",
          breadcrumb: "Expenses",
        },
      },
      {
        path: "accounts/closing-balance",
        loadChildren: () =>
          import(
            "./modules/gym/accounts/closing-balance/closing-balance.module"
          ).then((m) => m.AppClosingBalanceModule),
        data: {
          title: "Closing Balance",
          breadcrumb: "Closing Balance",
        },
      },
      {
        path: "accounts/opening-balance",
        loadChildren: () =>
          import(
            "./modules/gym/accounts/opening-balance/opening-balance.module"
          ).then((m) => m.AppOpeningBalanceModule),
        data: {
          title: "Opening Balance",
          breadcrumb: "Opening Balance",
        },
      },
      {
        path: "accounts/sundry-parties",
        loadChildren: () =>
          import(
            "./modules/gym/accounts/sundry-parties/sundry-parties.module"
          ).then((m) => m.AppSundryPartiesModule),
        data: {
          title: "Sundry parties",
          breadcrumb: "Sundry parties",
        },
      },
      // {
      //   path: "employee-list",
      //   loadChildren: () =>
      //     import("./modules/gym/group-of-employee/group-of-employee.module").then(
      //       (m) => m.EmployeeGroupModule
      //     ),
      //   data: { title: "Employee", breadcrumb: "Employee" },
      // },    
      {
        path: "slot-settings",
        loadChildren: () =>
          import("./modules/gym/slot-setting/slot-setting.module").then(
            (m) => m.AppSlotSettingModule
          ),
        data: { title: "Slot Settings", breadcrumb: "Slot Settings" },
      },
      {
        path: "site-setting",
        loadChildren: () =>
          import("./modules/gym/site-setting/site-setting.module").then(
            (m) => m.AppSiteSettingModule
          ),
        data: {
          title: "Site Setting",
          breadcrumb: "Site Setting",
        },
      },
      {
        path: "accounts/group",
        loadChildren: () =>
        import("./modules/gym/accounts/group/group.module").then(
          (m) => m.GroupModule),
        data: {title: "Group", breadcrumb: "Group"}
      },
      {
        path: "weight-receiver",
        loadChildren: () =>
          import("./modules/gym/weight-receiver/weight-receiver.module").then(
            (m) => m.AppWeightReceiverModule
          ),
        data: { title: "Weight Receiver", breadcrumb: "Weight Receiver" },
      },
      {
        path: "heartrate-receiver",
        loadChildren: () =>
          import("./modules/gym/heartrate-receiver/heartrate-receiver.module").then(
            (m) => m.AppHeartrateReceiverModule
          ),
        data: { title: "Heart Rate Receiver", breadcrumb: "Heart Rate Receiver" },
      },
      {
        path: "enquiry",
        loadChildren: () =>
          import("./modules/gym/enquiry/enquiry.module").then((m) => m.EnquiryModule),
        data: { title: "Enquiry", breadcrumb: "Enquiry" },
      }
    ],
  },
  {
    path: "physiotherapist",
    component: AdminLayoutComponent,
    canActivate: [PhysioAuthGuard],
    children: [
      {
        path: "info",
        loadChildren: () =>
          import(
            "./modules/physiotherapist/physiotherapist/physiotherapist.module"
          ).then((m) => m.AppPhysiotherapistModule),
        data: { title: "Physiotherapist", breadcrumb: "Physiotherapist" },
      },
      {
        path: "my-account",
        loadChildren: () =>
          import("./modules/physiotherapist/myaccount/myaccount.module").then(
            (m) => m.AppAccountModule
          ),
        data: { title: "My Account", breadcrumb: "My Account" },
      },
      {
        path: "notifications",
        loadChildren: () =>
          import(
            "./modules/physiotherapist/notifications/notifications.module"
          ).then((m) => m.PhysionNotificationsModule),
        data: { title: "Notifications", breadcrumb: "Notifications" },
      },
    ],
  },

  {
    path: "dietitian",
    component: AdminLayoutComponent,
    canActivate: [DietitianAuthGuard],
    children: [
      {
        path: "info",
        loadChildren: () =>
          import("./modules/dietitian/dietitian/dietitian.module").then(
            (m) => m.AppDietitianModule
          ),
        data: { title: "Dietitian", breadcrumb: "Dietitian" },
      },
      {
        path: "my-account",
        loadChildren: () =>
          import("./modules/dietitian/myaccount/myaccount.module").then(
            (m) => m.AppAccountModule
          ),
        data: { title: "My Account", breadcrumb: "My Account" },
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./modules/dietitian/notifications/notifications.module").then(
            (m) => m.DietitanNotificationsModule
          ),
        data: { title: "Notifications", breadcrumb: "Notifications" },
      },
     
    ],
  },

  {
    path: "trainer",
    component: AdminLayoutComponent,
    canActivate: [TrainerAuthGuard],
    children: [
      {
        path: "notifications",
        loadChildren: () =>
          import("./modules/trainer/notifications/notifications.module").then(
            (m) => m.TrainerNotificationsModule
          ),
        data: { title: "Notifications", breadcrumb: "Notifications" },
      },
    ],
  },

  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
