import { Component, OnInit, Input, OnDestroy, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "app/core/services/layout.service";
import { NavigationService } from "app/core/services/navigation.service";
import { ThemeService } from "app/core/services/theme.service";
import { UserService } from "app/core/services/user.service";
import { PermissionService } from "app/core/services/permission.service";
import { MatDialog } from "@angular/material/dialog";
import { GenerateRouteService } from "app/core/services/generate-route.service";
import { MessagingService } from "app/core/services/messaging.service";
import { PORTALTYPE } from "app/core/helpers/enum.helper";
import { decodedToken } from "app/core/helpers/token.helper";
import { get } from "lodash";
import { Router } from "@angular/router";
@Component({
  selector: "app-header-top",
  templateUrl: "./header-top.component.html",
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  tgssThemes: any[] = [];
  currentLang = "en";
  availableLangs = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "Spanish",
      code: "es",
    },
  ];
  @Input() notificPanel;

  portalType: string = "";
  userType: string = "";
  queueCount = 0;
  checkType: any = PORTALTYPE;
  userName: any;
  name: any;
  limit: any;
  user: any;
  details: any;
  token: any;
  
  constructor(
    public themeService: ThemeService,
    public translate: TranslateService,
    public navService: NavigationService,
    private layout: LayoutService,
    private userService: UserService,
    public permission: PermissionService,
    public dialog: MatDialog,
    private generateRouteService: GenerateRouteService,
    private messagingService: MessagingService,
    private router: Router
  ) {
    this.autoInit();
    this.getQueueValue();
    const token = window.localStorage.getItem("TGJWTTOKEN");
    this.messagingService.requestPermission(token);
    this.messagingService.receiveMessage();
  }
  public innerWidth: any;
  @HostListener('window:resize', ['$event'])
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.token = decodedToken();
    console.log(this.innerWidth, this.limit);
    this.layoutConf = this.layout.layoutConf;
    this.tgssThemes = this.themeService.tgssThemes;
    this.menuItemSub = this.navService.menuItems$.subscribe((res) => {
      res = res.filter(
        (item) => item.type !== "icon" && item.type !== "separator"
      );
      console.log(
        window.matchMedia(`(max-width: 1500px) and (min-width: 1366px)`)
          .matches,
        window.matchMedia(`(max-width: 1366px) and (min-width: 1250px)`)
          .matches,
        window.matchMedia(`(max-width: 1250px) and (min-width: 1000px)`)
          .matches,
        "window.matchMedia(`(max-width: 1500px)`).matches"
      );
      if (window.matchMedia(`(max-width: 1251px) and (min-width: 1100px)`).matches) {
        this.limit = 5;
      } else if (window.matchMedia(`(max-width: 1099px)`).matches) {
        this.limit = 4;
      } else if (window.matchMedia(`(max-width: 1367px) and (min-width: 1250px)`).matches) {
        this.limit = 7;
      } else if ( window.matchMedia(`(max-width: 1500px) and (min-width: 1367px)`).matches) {
        this.limit = 11;
      } else {
        this.limit = 11;
      }
      let mainItems: any[] = res.slice(0, this.limit);
      console.log(res.length <= this.limit, res.length, this.limit);
      if (res.length <= this.limit) {
        return (this.menuItems = mainItems);
      }
      let subItems: any[] = res.slice(this.limit, res.length);
      mainItems.push({
        name: "More",
        type: "dropDown",
        tooltip: "More",
        icon: "more_horiz",
        sub: subItems,
      });
      console.log(mainItems);

      this.menuItems = mainItems;
      console.log(this.menuItems);
      
    });
  }
  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
  }
  setLang() {
    this.translate.use(this.currentLang);
  }
  changeTheme(theme) {
    this.layout.publishLayoutChange({ matTheme: theme.name });
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }

  getQueueValue() {
    this.generateRouteService.queueCount.subscribe((res) => {
      this.queueCount = res >= 0 ? res : 0;
      console.log(this.queueCount);
    });
  }

  autoInit() {
    const token = decodedToken();
    this.details = decodedToken();
    console.log(token, "decoded Token");
    console.log(token.logo);
    
    this.portalType = get(token, "type") ?? "";
    this.userType = get(token, "user_type") ?? "";
    this.user = get(token, "acl_role_id") ?? "";
    this.userName = get(token, "trainer_name");
    this.name = get(token, "name");
  }

  logout(portalType) {
    this.messagingService.deleteToken();
    this.messagingService.sendFCMToken(null);
    if (portalType == PORTALTYPE.GYM) {
      this.userService.logout();
    } else {
      this.userService.logout();
    }
  }

  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log("screen size" ,this.innerWidth);
    this.menuItemSub = this.navService.menuItems$.subscribe((res) => {
      res = res.filter(
        (item) => item.type !== "icon" && item.type !== "separator"
      );
      if (window.matchMedia(`(max-width: 1251px) and (min-width: 1100px)`).matches) {
        this.limit = 5;
      } else if (window.matchMedia(`(max-width: 1099px)`).matches) {
        this.limit = 4;
      } else if (window.matchMedia(`(max-width: 1367px) and (min-width: 1250px)`).matches) {
        this.limit = 7;
      } else if ( window.matchMedia(`(max-width: 1500px) and (min-width: 1367px)`).matches) {
        this.limit = 11;
      } else {
        this.limit = 11;
      }
      let mainItems: any[] = res.slice(0, this.limit);
      console.log(res.length <= this.limit, res.length, this.limit);
      if (res.length <= this.limit) {
        return (this.menuItems = mainItems);
      }
      let subItems: any[] = res.slice(this.limit, res.length);
      mainItems.push({
        name: "More",
        type: "dropDown",
        tooltip: "More",
        icon: "more_horiz",
        sub: subItems,
      });
      this.menuItems = mainItems;
    });
  }

  redirection(){
    if(this.token.acl_role_id == 1){
      this.router.navigate(['/dashboard']);
    }
  }
}
