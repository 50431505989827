import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";
import { ApiService } from "app/core/services/api.service";
import { decodedToken } from "app/core/helpers/token.helper";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  token: any;

  constructor(private apiService: ApiService) {
    this.token = decodedToken();
  }

  getList(paginationDTO) {
    let params = new HttpParams();
    for (let key in paginationDTO) {
      params = params.set(key, paginationDTO[key]);
    }
    if(this.token?.acl_role_id != 2){
      return this.apiService.get('notification', params)
    }
    else if(this.token?.acl_role_id == 2){
      return this.apiService.get('tp_notification', params)
    }

  }

  setReadStatus(key): Observable<any> {
    return this.apiService.post('readNotification', key);
  }
  // // get one list
  // getOne(id): Observable<any> {
  //   return this.apiService
  //     .get(`notice/${id}`)
  //     .pipe(map((response) => this.adapter.adapt(response)));
  // }

  // // list with filtering pagination
  // getList(paginationDTO): Observable<INoticeBoardAPI> {
  //   let params = new HttpParams();
  //   for (let key in paginationDTO) {
  //     params = params.set(key, paginationDTO[key]);
  //   }
  //   return this.apiService
  //     .get(`notice`, params)
  //     .pipe(map((response) => this.adapter.adapt(response)));
  // }
}
